import React from 'react';
import css from './ListingPage.module.css';

function SectionCustomDetails({ publicData, intl, currentStock }) {
  const date = new Date(publicData?.startDate?.date);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date?.toLocaleDateString('en-US', options);
  const moveInDate = new Date(publicData?.moveInDate?.date);
  const formattedMoveInDate = moveInDate?.toLocaleDateString('en-US', options);
  let columns = [];
  if (publicData?.listingType === 'daily-rental') {
    columns = [
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.availableHomes' }),
        value: currentStock ? currentStock : 0,
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.maximumOccupancy' }),
        value: publicData?.maximumOccupancy ? publicData?.maximumOccupancy : 0,
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.availability' }),
        value: publicData?.availability === 'available' ? 'Available' : 'Unavailable',
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.nextAvailable' }),
        value: formattedDate ? formattedDate : '',
      },
    ];
  }
  if (publicData?.listingType === 'tenant-request') {
    columns = [
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.homesRequired' }),
        value: publicData?.homesRequired ? publicData?.homesRequired : 0,
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.occupancyRequired' }),
        value: publicData?.occupancyRequired ? publicData?.occupancyRequired : 0,
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.leaseTerm' }),
        value: publicData?.lease_term ? publicData?.lease_term : '',
      },
      {
        title: intl.formatMessage({ id: 'SectionCustomDetails.moveInDate' }),
        value: formattedMoveInDate ? formattedMoveInDate : '',
      },
    ];
  }

  return (
    <div className={css.detailsContainer}>
      {columns.map((column, index) => (
        <div key={index} className={css.detailsColumn}>
          <p className={css.detailsHeader}>{column.title}</p>
          <p className={css.detailsContent}>{column.value}</p>
        </div>
      ))}
    </div>
  );
}

export default SectionCustomDetails;
